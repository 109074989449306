import { createRouter, createWebHistory  } from 'vue-router'
import LayoutView from '../LayoutView.vue'
import HomeView from '@/views/HomeView.vue'
import IntroductionView from '@/views/IntroductionView.vue'
import ProjectsView from '@/views/ProjectsView.vue'
import CertificationsView from '@/views/CertificationsView.vue'
import ContactsView from '@/views/ContactsView.vue'
import CompanyView from '@/views/CompanyView.vue'
import SuccessView from '@/components/SuccessView.vue'

import ProjectView from '@/components/projects/ProjectView.vue'

import PrivacyPolicy from '@/views/PrivacyPolicy.vue'

const routes = [
  {
    path: '/',
    component: IntroductionView
  },
  {
    path: '/home',
    redirect: '/pt/home'
  },
  {
    path: '/:lang/privacy',
    component: PrivacyPolicy
  },
  {
    path: '/:lang/home',
    component: LayoutView,
    children: [
      {
        path: '/:lang/home/',
        component: HomeView,
        name: "Homepage"
      },
      {
        path: '/:lang/projetos',
        component: ProjectsView
      },
      {
        path: '/:lang/certificacoes',
        component: CertificationsView
      },
      {
        path: '/:lang/contatos',
        component: ContactsView
      },
      {
        path: '/:lang/empresa',
        component: CompanyView
      },
      {
        path: '/:lang/projeto/:id',
        component: ProjectView
      },
      {
        path: '/:lang/projeto/:id',
        component: ProjectView
      },
      {
        path: '/:lang/sucesso',
        component: SuccessView
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory (),
  routes
})

/*
router.beforeEach((to, from, next) => {
  i18n.locale = localStorage.getItem('lang') || 'pt'

  return next()
})*/

export default router
