<template>
    <div>
        <!--DESKTOP-->
        <v-container class="d-none d-lg-block" style="width:80%;">
                <div class="my-card py-16" style="color: white; position: sticky; overflow: hidden;">
                    <v-card height="auto" elevation="10" class="rounded-b-xl">
                        <v-toolbar color="primary">
                            <v-toolbar-title class="font-weight-black">
                                {{ $t('empresa.enquadramento.titulo') }}
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="ma-0 pa-0">
                            <v-row align="center" no-gutters>
                                <v-col cols="12" md="5" class="text-justify" style="justify: center;">
                                    <v-img class="pointer" :src="images.company.img1" cover></v-img>
                                </v-col>
                                <v-col cols="12" md="7">
                                    <v-card-text class="text-justify" v-html="$t('empresa.enquadramento.descricao')">
                                        
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </div>
                <div class="my-card mt-16 py-16" style="color: white; position: sticky;">
                    <v-card height="auto" elevation="14" class="rounded-b-xl">
                        <v-toolbar color="primary">
                            <v-toolbar-title>
                                {{ $t('empresa.visao.titulo') }}
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="ma-0 pa-0">
                            <v-row align="center">
                                <v-col cols="12" md="7">
                                    <v-card-text class="text-justify mx-16" v-html="$t('empresa.visao.descricao')">
                                        
                                    </v-card-text>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-img class="pointer" :src="images.company.img2" contain></v-img>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </div>     
                <div class="my-card mb-16 py-16" style="color: white; position: sticky;">
                    <v-card height="auto" elevation="14" class="rounded-b-xl">
                        <v-toolbar color="primary">
                            <v-toolbar-title>
                                {{ $t('empresa.missao.titulo') }}
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="ma-0 pa-0">
                            <v-row align="center">
                                <v-col cols="12" md="5">
                                    <v-img class="pointer" :src="images.company.img2" contain></v-img>
                                </v-col>
                                <v-col cols="12" md="7">
                                    <v-card-text class="text-justify mx-16" v-html="$t('empresa.missao.descricao')">
                                        
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </div>  
                <div class="my-card mb-16 py-16" style="color: transparent; position: sticky; z-index: -1 !important;" >
                    <v-card height="100" elevation="0" class="rounded-b-xl mt-16 mb-14 transparent" style="z-index: -1 !important;">
                        

                    </v-card>
                </div> 
        </v-container>
        <!--MOBILE-->
        <v-container class="d-md-none" style="width:100%;">
                <div class="py-16" style="color: white; overflow: hidden;">
                    <v-card height="auto" elevation="10" class="rounded-b-xl">
                        <v-toolbar color="primary">
                            <v-toolbar-title class="font-weight-black">
                                {{ $t('empresa.enquadramento.titulo') }}
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="ma-0 pa-0">
                            <v-row align="center" no-gutters>
                                <v-col cols="12" md="5" class="text-justify" style="justify: center;">
                                    <v-img class="pointer" :src="images.company.img1" cover></v-img>
                                </v-col>
                                <v-col cols="12" md="7">
                                    <v-card-text class="text-justify" v-html="$t('empresa.enquadramento.descricao')">
                                        
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </div>
                <div class="mt-16 py-16" style="color: white;">
                    <v-card height="auto" elevation="14" class="rounded-b-xl">
                        <v-toolbar color="primary">
                            <v-toolbar-title>
                                {{ $t('empresa.visao.titulo') }}
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="ma-0 pa-0">
                            <v-row align="center" no-gutters>
                                <v-col cols="12" md="7">
                                    <v-col cols="12" md="5">
                                        <v-img class="pointer" :src="images.company.img2" contain></v-img>
                                    </v-col>
                                    <v-card-text class="text-justify" v-html="$t('empresa.visao.descricao')">
                                        
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </div>     
                <div class="mb-16 py-16" style="color: white;">
                    <v-card height="auto" elevation="14" class="rounded-b-xl">
                        <v-toolbar color="primary">
                            <v-toolbar-title>
                                {{ $t('empresa.missao.titulo') }}
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="ma-0 pa-0">
                            <v-row align="center">
                                <v-col cols="12" md="5">
                                    <v-img class="pointer" :src="images.company.img1" contain></v-img>
                                </v-col>
                                <v-col cols="12" md="7">
                                    <v-card-text class="text-justify" v-html="$t('empresa.missao.descricao')">
                                        
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </div>  
        </v-container>
    </div>
</template>
<script>

export default{
    name: 'CompanyView',
    data(){
        return{
            images:{
                company:{
                    img1: require('@/assets/company/261.jpg'),
                    img2: require('@/assets/company/polo2.jpg')
                }
            }
        }
    },
    mounted(){
        if(this.$route.params.language != undefined){
            this.$i18n.locale = this.$route.params.language
        }
        window.scrollTo({top: 0, behavior: 'smooth'})
        
    }
}
</script>
<style>

.my-card{
    position: sticky;
    top: 8em;
    padding: 1.5em;
    border-radius: 1em;
}

@media(min-width: 850px){
    :root {
        --cards: 3;
        --cardHeight: 75vh;
        --cardTopPadding: 1.5em;
        --cardMargin: 4vw;
    }

    .container {
        width: 90%;
        margin: 0 auto;
    }

    #cards {
        list-style: none;
        padding-left: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(var(--cards), var(--cardHeight));
        gap: var(--cardMargin);
        padding-bottom: calc(var(--cards) * var(--cardTopPadding));
        margin-bottom: var(--cardMargin);
    }


    .card {
        position: sticky;
        top: 0;
        padding-top: calc(var(--index) * var(--cardTopPadding));
    }


    h2 {
        font-size: 2.5em;
    }
}
</style>