<template>
    <v-container class="ma-0 pa-0 bg" fluid style="height: 100vh !important;">
      <CookieView v-if="false" :snackbarProp="showWindowCookie" @cookie-accept="cookieAccept" @cookie-reject="cookieReject" />
      <v-container fluid style="position: absolute; bottom: 10vh;">
        <Transition>
          <div class="wrapper" v-show="showImage">
            <div class="link_wrapper">
                <a @click="$router.push('home')" style="cursor: pointer;">Entrar</a>
                <div class="icon">
                <svg viewBox="0 0 268.832 268.832">
                    <path d="M265.17 125.577l-80-80c-4.88-4.88-12.796-4.88-17.677 0-4.882 4.882-4.882 12.796 0 17.678l58.66 58.66H12.5c-6.903 0-12.5 5.598-12.5 12.5 0 6.903 5.597 12.5 12.5 12.5h213.654l-58.66 58.662c-4.88 4.882-4.88 12.796 0 17.678 2.44 2.44 5.64 3.66 8.84 3.66s6.398-1.22 8.84-3.66l79.997-80c4.883-4.882 4.883-12.796 0-17.678z"/>
                </svg>
              </div>
            </div>
          </div>
        </Transition>
      </v-container>
        
        <v-container fluid style="position: absolute; bottom: 15vh;" v-show="showImage">
          <v-row no-gutters>
            <v-col cols="12">
                <v-img
                    @click="$router.push('home')"
                    :src="require('@/assets/logos/logo_edirio.png')"
                    style="margin: auto; display: block;"
                    width="50vh"
                />
            </v-col>
          </v-row>
        </v-container>
          
       
    </v-container>
</template>
<script>
import CookieView from '@/components/cookies/CookieView.vue'

export default{
    name: "IntroductionView",
    components:{
      CookieView
    },
    data(){
        return{
            showCookieBar: false,
            showImage: false
        }
    },
    mounted(){
        this.showImage = true
        let cookie = this.$cookies.get('edirio_cookie');
        //this.$cookies.set('edirio_cookie', JSON.stringify(cookie), '1s');
        if(cookie == null)
            this.showCookieBar = true
    },
    methods:{
      cookieReject(){
        this.$cookies.set('edirio_cookie', null, '1s');
        this.showCookieBar = false
      },
      cookieAccept(ob){
        this.$cookies.set('edirio_cookie', JSON.stringify(ob), '7d');
        this.showCookieBar = false
      },
    },
    computed:{      
      showWindowCookie(){
        return this.showCookieBar
      },
    }
}
</script>
<style>
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 1.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.bg {
  /* The image used */
  background-image: url("@/assets/ponte_metalica.JPG");

  /* Full height */
  height: 100%; 

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.wrapper{
  position: absolute;
  background-color: rgba(0,0,0,0.75);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.link_wrapper{
  position: relative;
}

a{
  display: block;
  width: 350px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  text-decoration: none;
  background: rgba(0,0,0,0.75);
  text-align: center;
  color: rgba(255,255,255,0.75);
  text-transform: uppercase;
  letter-spacing: 5px;
  border: 1px solid rgba(0,0,0,0.75);
  transition: all .35s;
}

.icon{
  width: 50px;
  height: 50px;
  border: 3px solid transparent;
  position: absolute;
  transform: rotate(45deg);
  right: 0;
  top: 0;
  z-index: -1;
  transition: all .35s;
}

.icon svg{
  width: 30px;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  transform: rotate(-45deg);
  fill: rgba(255,255,255,0.75);
  transition: all .35s;
}

a:hover{
  width: 200px;
  border: 3px solid rgba(255,255,255,0.75);
  background: transparent;
  color: rgba(255,255,255,0.75);
}

a:hover + .icon{
  border: 3px solid rgba(255,255,255,0.75);
  right: -25%;
  background-color: rgba(0,0,0,0.75);
}

</style>