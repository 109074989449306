<template>
  <v-container fluid class="fill-height ma-0 pa-0 home-bg">
    
  </v-container>
</template>

<script>

export default {
  name: 'HomeView',
}
</script>
<style>
.home-bg {
  /* The image used */
  background-image: url("@/assets/homepage/bridge_view.jpg");

  /* Full height */
  height: 100%; 

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

</style>