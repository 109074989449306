<template>
    <v-app-bar fixed hight="5vh">
        
        <img @click="$router.push('/')" class="pointer" src="@/assets/logos/logo_edirio.png" height="100">

        <v-spacer></v-spacer>
        <v-tabs right background-color="#EEE4CB" class="d-none d-lg-block">
            <v-tab @click="goTo('home')">{{ $t('menu.inicio') }}</v-tab>
            <v-tab @click="goTo('empresa')">{{ $t('menu.empresa') }}</v-tab>
            <v-tab @click="goTo('projetos')">{{ $t('menu.projetos') }}</v-tab>
            <v-tab @click="goTo('certificacoes')">{{ $t('menu.certificacoes') }}</v-tab>
            <v-tab @click="goTo('contatos')">{{ $t('menu.contactos') }}</v-tab>
        </v-tabs>
                
        <v-spacer></v-spacer>
        <div class="d-none d-lg-block s0VQNf" bis_skin_checked="1">
            <div class="WfZwmg _ap_Ez wixui-language-menu" data-testid="languages-container" aria-label="Language Selector - Portuguese selected" bis_skin_checked="1">
                <button aria-label="French" aria-current="false" class="wixui-language-menu__option mr-4" @click="changeLanguage('fr')">
                    <div class="LEHGju" bis_skin_checked="1">
                        <img src="https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/FRA.png" 
                        srcset="https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/FRA.png, 
                        https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/FRA_2x.png 2x" alt="French">
                    </div>
                    <div class="J6PIw1" bis_skin_checked="1">
                        FR
                    </div>
                </button>
                <button aria-label="French" aria-current="false" class="wixui-language-menu__option mr-4" @click="changeLanguage('pt')">
                    <div class="LEHGju" bis_skin_checked="1">
                        <img src="https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/PRT.png" 
                        srcset="https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/PRT.png, 
                        https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/PRT_2x.png 2x" alt="French">
                    </div>
                    <div class="J6PIw1" bis_skin_checked="1">
                        PT
                    </div>
                </button>
                <button aria-label="French" aria-current="false" class="wixui-language-menu__option mr-4" @click="changeLanguage('en')">
                    <div class="LEHGju" bis_skin_checked="1">
                        <img src="https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/GBR.png" 
                        srcset="https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/GBR.png, 
                        https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/GBR_2x.png 2x" alt="French">
                    </div>
                    <div class="J6PIw1" bis_skin_checked="1">
                        EN
                    </div>
                </button>
            </div>
        </div>

        <div class="d-md-none mr-2">
            <v-menu>
            <template v-slot:activator="{ props }">
                <v-btn
                color="primary"
                v-bind="props"
                >
                    <div v-if="$i18n.locale == 'pt'" class="LEHGju" bis_skin_checked="1">
                        <img src="https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/PRT.png" 
                        srcset="https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/PRT.png, 
                        https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/PRT_2x.png 2x" alt="French">
                    </div>

                    <div v-if="$i18n.locale == 'en'" class="LEHGju" bis_skin_checked="1">
                        <img src="https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/GBR.png" 
                            srcset="https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/GBR.png, 
                            https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/GBR_2x.png 2x" alt="French">
                    </div>

                    <div v-if="$i18n.locale == 'fr'" class="LEHGju" bis_skin_checked="1">
                        <img src="https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/FRA.png" 
                        srcset="https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/FRA.png, 
                        https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/FRA_2x.png 2x" alt="French">
                    </div>
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    :key="1"
                    :value="'pt'"
                >
                    <v-list-item-title>
                        <button aria-label="French" aria-current="false" class="wixui-language-menu__option" @click="changeLanguage('pt')">
                            <div class="LEHGju" bis_skin_checked="1">
                                <img src="https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/PRT.png" 
                                srcset="https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/PRT.png, 
                                https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/PRT_2x.png 2x" alt="French">
                            </div>
                            <div class="J6PIw1" bis_skin_checked="1">
                                PT
                            </div>
                        </button>
                    </v-list-item-title>
                    <v-list-item-title>
                        <button aria-label="French" aria-current="false" class="wixui-language-menu__option" @click="changeLanguage('en')">
                            <div class="LEHGju" bis_skin_checked="1">
                            <img src="https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/GBR.png" 
                                srcset="https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/GBR.png, 
                                https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/GBR_2x.png 2x" alt="French">
                            </div>
                            <div class="J6PIw1" bis_skin_checked="1">
                                EN
                            </div>
                        </button>
                    </v-list-item-title>
                    <v-list-item-title>
                        <button aria-label="French" aria-current="false" class="wixui-language-menu__option" @click="changeLanguage('fr')">
                            <div class="LEHGju" bis_skin_checked="1">
                                <img src="https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/FRA.png" 
                                srcset="https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/FRA.png, 
                                https://static.parastorage.com/services/linguist-flags/1.663.0/assets/flags/round/FRA_2x.png 2x" alt="French">
                            </div>
                            <div class="J6PIw1" bis_skin_checked="1">
                                FR
                            </div>
                        </button>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
            </v-menu>

        </div>
        <v-btn icon right @click="$emit('openMenu')" class="d-md-none mr-2">
            <v-icon>mdi-menu</v-icon>
        </v-btn>
        
            
    </v-app-bar>
</template>
<script>

export default{
    mounted(){
        if(this.$route.params.language != undefined){
            this.$i18n.locale = this.$route.params.language
        }
    },
    methods:{
        goTo(place){
            this.$router.push('/' + this.$i18n.locale + '/' + place)
        },
        changeLanguage(l){            
            this.$emit('switchLocale', l)
        }
    }
}
</script>