<template>
    <v-container fluid>
        <v-card class="elevation-0">
            <v-card-title>
                Política de Cookies
            </v-card-title>
            <v-card-text class="text-start">             
                <div>
                    <div>
                    <p><strong>1. Enquadramento&nbsp;</strong></p>
                    <p>&nbsp;</p>
                    </div>
                    <div>
                    <p>Se geridas indevidamente os cookies do website da EDIRIO, este representa canal de um acesso a dados pessoais dos visitantes.&nbsp;</p>
                    <p>&nbsp;</p>
                    </div>
                    <div>
                    <p><strong>2. Objetivo&nbsp;</strong></p>
                    <p>&nbsp;</p>
                    </div>
                    <div>
                    <p>Definir a forma como s&atilde;o tratados e utilizados os cookies utilizados pelo website da EDIRIO, em&nbsp;<span @click="openEdirio"><u>https://www.edirio.pt/</u></span>.&nbsp;</p>
                    <p>&nbsp;</p>
                    </div>
                    <div>
                    <p><strong>3. &Acirc;mbito de aplica&ccedil;&atilde;o&nbsp;</strong></p>
                    <p>&nbsp;</p>
                    </div>
                    <div>
                    <p>Esta pol&iacute;tica aplica-se a todos visitantes do website da EDIRIO.&nbsp;</p>
                    <p>&nbsp;</p>
                    </div>
                    <div>
                    <p><strong>4. Pol&iacute;tica &ndash; Operacionalidade&nbsp;</strong></p>
                    </div>
                    <div>
                    <p class="ml-4"><strong>4.1. Tipo de cookies&nbsp;</strong></p>
                    <p>&nbsp;</p>
                    </div>
                    <div>
                    <p class="ml-4">O website da EDIRIO utiliza apenas cookies anal&iacute;ticos.&nbsp;</p>
                    <p>&nbsp;</p>
                    </div>
                    <div>
                    <p class="ml-4"><strong>4.2. Cookies anal&iacute;ticos&nbsp;</strong></p>
                    <p>&nbsp;</p>
                    </div>
                    <div>
                    <p class="ml-4">Os cookies anal&iacute;ticos (Google&nbsp;analytics) s&atilde;o utilizados anonimamente para analisar a forma como os utilizadores usam o website e monitorizar a sua performance. S&atilde;o utilizados apenas para efeitos de cria&ccedil;&atilde;o e an&aacute;lise estat&iacute;stica, sem nunca recolher informa&ccedil;&atilde;o de car&aacute;cter pessoal.&nbsp;</p>
                    </div>
                    <div>
                    <p class="ml-4">Os cookies anal&iacute;ticos da Google&nbsp;Analytic&nbsp;s&atilde;o armazenados da seguinte forma:&nbsp;</p><br/>
                    </div>
                    <div class="ml-16">
                    <ul>
                    <li>_ga&nbsp;&eacute; utilizado para diferenciar os utilizadores e caduca ao fim de dois anos&nbsp;</li>
                    </ul>
                    </div>
                    <div class="ml-16">
                    <ul>
                    <li>_gid&nbsp;&eacute; utilizado para diferenciar os utilizadores e caduca ao fim de 24 horas&nbsp;</li>
                    </ul>
                    </div>
                    <div class="ml-16">
                    <ul>
                    <li>_gat&nbsp;&eacute; utilizado para acelerar pedidos e caduca ao fim de 10 minutos&nbsp;</li>
                    </ul>
                    <p>&nbsp;</p>
                    </div>
                    <div>
                    <p><strong>4.3. Gest&atilde;o de cookies&nbsp;</strong></p>
                    <p>&nbsp;</p>
                    </div>
                    <div>
                    <p>Ao navegar no website da EDIRIO, o utilizador pode concordar, recusar ou selecionar o tipo a utiliza&ccedil;&atilde;o dos cookies.&nbsp;</p>
                    </div>
                    <div>
                    <p>Depois de autorizar o uso de cookies, o utilizador pode sempre desativ&aacute;-los, em parte ou na totalidade, atrav&eacute;s das defini&ccedil;&otilde;es do browser.&nbsp;</p>
                    </div>
                    <div>
                    <p>Ermesinde, 09 de janeiro de 2024&nbsp;</p>
                    </div>
                    <div>
                    <p>&nbsp;</p>
                    </div>
                    </div>
                    <div>&nbsp;</div>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default{
    methods:{
        openEdirio(){
            window.open('https://www.edirio.pt/', '_blank')
        }
    }
}
</script>