<template>
    <v-container fluid class="fill-height">
        <div class="d-none d-lg-block" style="width: 100%;">
            <v-card class="ma-16 pa-16" elevation="0">
                <v-row class="mt-n8">
                    <v-col cols="12" md="6">
                        <GoogleMap api-key="AIzaSyCnLaIy1eUdlZs83_KTePQtnVHXmCln9PQ" style="width: 100%; height: 500px" :center="center" :zoom="15">
                            <Marker :options="{ position: center }" />
                        </GoogleMap>
            
                        <v-col cols="12" class="text-justify">
                            <span class="text-body-1 text-uppercase text-justify">
                                EDIRIO CONSTRUÇÕES, SA
                            </span>
                            <span class="text-body-1">
                                <br/>Lugar da Portela Alta – EN 105
                                <br/>Rua Dr. Francisco Sá Carneiro, 22
                                <br/>4825-087 Água Longa, Portugal
                            </span>
                        </v-col>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card>               
                            <v-toolbar color="primary">
                                <v-toolbar-title>
                                    <span class="text-h4 text-uppercase">
                                        {{ $t('contactos.title') }}
                                    </span>
                                </v-toolbar-title>
                            </v-toolbar>
                            <v-card-text class="mx-12 px-12">
                                <v-form :disabled="loading">
                                    <v-row>
                                        <v-col cols="12" md="6">
                                                <v-text-field
                                                    variant="outlined"
                                                    v-model="contacts.name"
                                                    prepend-inner-icon="mdi-account-details"
                                                    label="Nome *"
                                                    clearable
                                                ></v-text-field>                          
                                        </v-col>
                                        <v-col cols="12" md="6">
                                                <v-text-field
                                                    :rules="['email']"
                                                    variant="outlined"
                                                    v-model="contacts.email"
                                                    prepend-inner-icon="mdi-at"
                                                    label="Email *"
                                                    clearable
                                                ></v-text-field>                         
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                variant="outlined"
                                                v-model="contacts.subject"
                                                prepend-inner-icon="mdi-text-short"
                                                label="Subject *"
                                                clearable
                                            ></v-text-field>                        
                                        </v-col>
                                        <v-col cols="12">
                                            <v-textarea
                                                variant="outlined"
                                                v-model="contacts.message"
                                                prepend-inner-icon="mdi-text-long"
                                                label="Message"
                                                clearable
                                            ></v-textarea>                       
                                        </v-col>
                                        <v-col cols="12">
                                            <v-btn @click="contact" depressed color="primary" class="font-weight-bold">
                                                {{ $t('contactos.enviar') }}
                                            </v-btn> 
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            <v-card-text class="text-body-1">Tel: +351 22 968 84 11<br/> Email: geral@edirio.pt</v-card-text>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="6" class="text-start">
                                        <span style="cursor:pointer; text-decoration: underline;" @click="goTo()">{{$t('contactos.normative')}}<br/>{{$t('contactos.complaints')}}</span>
                                    </v-col>
                                    <v-col cols="6" class="text-end">
                                        <br/>
                                        <span style="cursor:pointer; text-decoration: underline;" @click="openPolicy()">{{$t('cookies.cookies_policy')}}</span>
                                    </v-col>
                                </v-row>                                
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </div>
        <div class="d-md-none">
            <v-card class="ma-0 pa-0" elevation="0">
                <v-row class="mt-8">                    
                    <v-col cols="12" md="6">
                        <v-card>               
                            <v-toolbar color="primary">
                                <v-toolbar-title>
                                    <span class="text-h4 text-uppercase">
                                        {{ $t('contactos.title') }}
                                    </span>
                                </v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-form :disabled="loading">
                                    <v-row>
                                        <v-col cols="12">
                                                <v-text-field
                                                    variant="outlined"
                                                    v-model="contacts.name"
                                                    prepend-inner-icon="mdi-account-details"
                                                    label="Nome *"
                                                    clearable
                                                ></v-text-field>                          
                                        </v-col>
                                        <v-col cols="12">
                                                <v-text-field
                                                    :rules="['email']"
                                                    variant="outlined"
                                                    v-model="contacts.email"
                                                    prepend-inner-icon="mdi-at"
                                                    label="Email *"
                                                    clearable
                                                ></v-text-field>                         
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                variant="outlined"
                                                v-model="contacts.subject"
                                                prepend-inner-icon="mdi-text-short"
                                                label="Subject *"
                                                clearable
                                            ></v-text-field>                        
                                        </v-col>
                                        <v-col cols="12">
                                            <v-textarea
                                                variant="outlined"
                                                v-model="contacts.message"
                                                prepend-inner-icon="mdi-text-long"
                                                label="Message"
                                                clearable
                                            ></v-textarea>                       
                                        </v-col>
                                        <v-col cols="12">
                                            <v-btn :disabled="disableButton" @click="contact()" depressed color="primary" class="font-weight-bold">
                                                {{ $t('contactos.enviar') }}
                                            </v-btn> 
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            <v-card-text class="text-body-1">Tel: +351 22 968 84 11<br/> Email: geral@edirio.pt</v-card-text>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="6" class="text-start">
                                        <span style="cursor:pointer; text-decoration: underline;" @click="goTo()">{{$t('contactos.normative')}}<br/>{{$t('contactos.complaints')}}</span>
                                    </v-col>
                                    <v-col cols="6" class="text-end">
                                        <br/>
                                        <span style="cursor:pointer; text-decoration: underline;" @click="openPolicy()">{{$t('cookies.cookies_policy')}}</span>
                                    </v-col>
                                </v-row>                                
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                        <GoogleMap api-key="AIzaSyCnLaIy1eUdlZs83_KTePQtnVHXmCln9PQ" style="width: 100%; height: 500px" :center="center" :zoom="15">
                            <Marker :options="{ position: center }" />
                        </GoogleMap>
            
                        <v-col cols="12" class="text-justify">
                            <span class="text-body-1 text-uppercase text-justify">
                                EDIRIO CONSTRUÇÕES, SA
                            </span>
                            <span class="text-body-1">
                                <br/>Lugar da Portela Alta – EN 105
                                <br/>Rua Dr. Francisco Sá Carneiro, 22
                                <br/>4825-087 Água Longa, Portugal
                            </span>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </div>
        <ErrorSnackbar v-model="error.show" :message="error.msg" style="z-index: 9999 !important;"/>
    </v-container>
</template>
<script>
import { GoogleMap, Marker } from "vue3-google-map";
import ErrorSnackbar from "@/components/ui/ErrorSnackbar"
import * as FormData from 'form-data'; 
import Mailgun from 'mailgun.js'; 

export default {
    name: 'ContactsView',
    components: { GoogleMap, Marker, ErrorSnackbar },
    data(){
        return{
            loading: true,
            disableButton: false,
            error:{
                show: false,
                msg: ''
            },
            valid: true,
            contacts:{
                name: null,
                email: null,
                subject: null,
                message: null
            },
            center: {lat: 41.24967775204666, lng: -8.5104816418242},
            markers: [
                {
                position: {
                    lat: 41.24967775204666, lng: -8.5104816418242
                },
                }
                , // Along list of clusters
            ]
        }
    },
    mounted(){
        if(this.$route.params.language != undefined){
            this.$i18n.locale = this.$route.params.language
        }
        let cookie = this.$cookies.get('edirio_cookie');

        if(cookie == undefined)
            return
        if(cookie == null)
            return
        if(cookie.statistics == false)
            return
            

        this.$gtag.event('call_tag', {
          'event_category': 'AgendarChamada',
          'event_label': 'AgendarChamada',
          'value': 'call_tag'
        })
        
        this.loading = false
    },
    methods:{
        openPolicy(){
            window.open("/" + this.$i18n.locale + '/privacy', '_blank')
        },
        goTo(){
            //href="https://www.conduril.pt/download/2022-05-Prevencao_e_combate_a_corrupcao-PT.pdf" target="_blank"
            //window.open('https://www.conduril.pt/download/2022-05-Prevencao_e_combate_a_corrupcao-PT.pdf', '_blank')
            window.open('https://incognito.conduril.pt/pt', '_blank')
        },
        contact(){ 

            if(this.contacts.name == '' || this.contacts.name == null){
                this.error.msg = "Coloque o nome";
                this.error.show = true;
                return
            }

            if(this.contacts.email == '' || this.contacts.email == null){
                this.error.msg = "Coloque o email";
                this.error.show = true;
                return
            }

            if(this.contacts.subject == '' || this.contacts.subject == null){
                this.error.msg = "Coloque titulo na sua mensagem";
                this.error.show = true;
                return
            }

            if(this.contacts.message == '' || this.contacts.message == null){
                this.error.msg = "Coloque uma mensagem para enviar no seu contacto";
                this.error.show = true;
                return
            }

            const mailgun = new Mailgun(FormData); 

            const mg = mailgun.client( 

                    { 

                        username: 'api',  

                        key: process.env.VUE_APP_MAILGUN_KEY,

                        url:"https://api.eu.mailgun.net" 

                    } 

                ); 

            let message =   "Novo contacto!<br/><br/>" +
                            "Nome: " + this.contacts.name + "<br/><br/>" +
                            "Email: " + this.contacts.email + "<br/><br/>" +
                            "Assunto: " + this.contacts.subject + "<br/><br/>" +
                            "Mensagem: " + this.contacts.message + "<br/><br/>";

            mg.messages.create('email.edirio.pt', { 

            from: "info@edirio.com", 

            to: ['geral@edirio.pt'], 

            subject: "Nova mensagem recebida", 

            text: "Nova mensagem", 

            html: message

            }) 

            .then(msg => console.log(msg)) // logs response data 

            .catch(err => console.error(err)); // logs any error 


            this.disableButton = true
            this.$router.push('/' + this.$i18n.locale + '/' + 'sucesso')

        }, 
    }
}
</script>