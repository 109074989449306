<template>
    <v-theme-provider>
        <v-app>
            <HeaderView @switchLocale="switchLocale" @openMenu="openMobileMenu = true;"/>
            <v-main >
                <router-view></router-view>
                <CookieView v-if="false" :snackbarProp="showWindowCookie" @cookie-accept="cookieAccept" @cookie-reject="cookieReject" />

                <v-expand-transition>
                    <v-scroll-y-reverse-transition v-show="openMobileMenu">
                        <v-container fluid style="position: fixed; bottom: 0; z-index: 100;">
                            <v-card class="white--text rounded-xl">
                                <v-card-title style='background-color: #1B3544'  class="text-center text-uppercase font-weight-bold text-h6">
                                    <span class="ml-12 text-white">Menu</span>
                                    <v-btn style="float: right;" variant="text" @click="openMobileMenu = false;">
                                        <v-icon x-large class="text-white">mdi-close</v-icon>
                                    </v-btn>
                                </v-card-title>
                            <v-divider color="white"></v-divider>
                            <v-card-text class="text-uppercase font-weight-bold text-body-1">
                                <v-row>
                                    <v-col cols="12">
                                        <v-btn variant="text" @click="goTo('home')">
                                            {{ $t('menu.inicio') }}
                                        </v-btn>
                                    </v-col>
                                    <v-divider></v-divider>
                                    <v-col cols="12">
                                        <v-btn variant="text" @click="goTo('empresa')">
                                            {{ $t('menu.empresa') }}
                                        </v-btn>
                                    </v-col>
                                    <v-divider></v-divider>
                                    <v-col cols="12">
                                        <v-btn variant="text" @click="goTo('projetos')">
                                            {{ $t('menu.projetos') }}
                                        </v-btn>
                                    </v-col>
                                    <v-divider></v-divider>
                                    <v-col cols="12">
                                        <v-btn variant="text" @click="goTo('certificacoes')">
                                            {{ $t('menu.certificacoes') }}
                                        </v-btn>
                                    </v-col>
                                    <v-divider></v-divider>
                                    <v-col cols="12">
                                        <v-btn variant="text" @click="goTo('contatos')">
                                            {{ $t('menu.contactos') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            </v-card>
                        </v-container>
                    </v-scroll-y-reverse-transition>
                </v-expand-transition>

            </v-main>
        </v-app>
    </v-theme-provider>
</template>
<script>
import HeaderView from '@/components/header/HeaderView.vue'
import CookieView from '@/components/cookies/CookieView.vue'

export default{
    name: 'LayoutView',
    components: {
        HeaderView,
        CookieView
    },
    data(){
        return{
            openMobileMenu: false,
            showCookieBar: false
        }
    },
    mounted(){
        let cookie = this.$cookies.get('edirio_cookie');
        //this.$cookies.set('edirio_cookie', JSON.stringify(cookie), '1s');
        if(cookie == null)
            this.showCookieBar = true
    },
    methods:{
        switchLocale(l){
            this.$i18n.locale = l

            
            let newLanguage = this.$i18n.locale


            this.$router.push({name: this.$router.currentRoute._value.name, params: {lang: newLanguage}})
        },
        cookieReject(){
            this.$cookies.set('edirio_cookie', null, '1s');
            this.showCookieBar = false
        },
        cookieAccept(ob){
            this.$cookies.set('edirio_cookie', JSON.stringify(ob), '7d');
            this.showCookieBar = false
        },
        goTo(place){
            this.openMobileMenu = false; 
            this.$router.push('/' + this.$i18n.locale + '/' + place)
        }
    },
    computed:{      
      showWindowCookie(){
        return this.showCookieBar
      },
      getYear(){
        let date = new Date()

        return date.getFullYear();
      }
    }
}
</script>