<template>
  <v-container fluid>
    <!-- DESKTOP -->
    <div class="d-none d-lg-block">
      <v-card v-if="!loading"  class="ma-16 pa-16 mt-n6">
        <v-container fluid>
          <div style="position: absolute;" >
            <v-btn variant="text" @click="goTo('projetos')">
              <v-icon>mdi-arrow-left</v-icon> {{ $t('botoes.voltar') }}
            </v-btn>
          </div>
          <div v-html="projectOpen.description"></div>
        </v-container>
        <v-row class="pa-2 mt-4">
        <v-col
          v-for="n in projectOpen.imgs"
          :key="n"
          class="d-flex child-flex"
          cols="2"
        >
          <v-img
            v-if="!isVideo(n)"
            @click="openImage(n)"
            style="cursor: pointer;"
            :src="n"
            :lazy-src="n"
            aspect-ratio="1"
            cover
            class="bg-grey-lighten-2 align-end"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey-lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <video width="400" controls v-else style="cursor: pointer;" @click="openImage(n)">
            <source :src="n" type="video/mp4">
            Your browser does not support HTML video.
          </video>

        </v-col>
      </v-row>
      <ModalSlideshow v-if="openModal" @close="openModal = false;" :items="projectOpen.imgs" :selected="imageOpen"/>
      </v-card>
    </div>
    <div class="d-md-none">
      <v-card v-if="!loading"  class="ma-0 pa-0">
        <v-container fluid>
          <div>
            <v-btn variant="outlined" @click="goTo('projetos')">
              <v-icon>mdi-arrow-left</v-icon> {{ $t('botoes.voltar') }}
            </v-btn>
          </div>
          <div class="mt-6" v-html="projectOpen.description"></div>
        </v-container>
        <v-row class="pa-2 mt-4">
        <v-col
          v-for="n in projectOpen.imgs"
          :key="n"
          class="d-flex child-flex"
          cols="4"
        >
          <v-img
            v-if="!isVideo(n)"
            @click="openImage(n)"
            style="cursor: pointer;"
            :src="n"
            :lazy-src="n"
            aspect-ratio="1"
            cover
            class="bg-grey-lighten-2 align-end"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey-lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <video width="400" controls v-else style="cursor: pointer;" @click="openImage(n)">
            <source :src="n" type="video/mp4">
            Your browser does not support HTML video.
          </video>

        </v-col>
      </v-row>
      <ModalSlideshow v-if="openModal" @close="openModal = false;" :items="projectOpen.imgs" :selected="imageOpen"/>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import ModalSlideshow from '@/components/ui/ModalSlideshow.vue'

export default{
    name: 'ProjectView',
    components:{
        ModalSlideshow
    },
    data(){
        return{
            loading: true,
            openModal: false,
            projectOpen: null,
            projects:[
              {
                id: 1,
                name: 'Doca 2 - Sul',
                address: 'Leixões',
                description: 'Doca 2 Sul<br/>APDL<br/>Ano: 2017',
                imgs: [
                  require('@/assets/projects/apdl/DJI_0018.png'),
                  require('@/assets/projects/apdl/Doca 2 Sul.png'),
                  require('@/assets/projects/apdl/FB_IMG_1523352729380.png'),
                  require('@/assets/projects/apdl/IMG_4016_edited.png'),
                  require('@/assets/projects/apdl/IMG_5188.png'),
                  require('@/assets/projects/apdl/IMG_5358.png'),
                  require('@/assets/projects/apdl/IMG_5517.png'),
                  require('@/assets/projects/apdl/IMG_5520.png'),
                  require('@/assets/projects/apdl/IMG_5521.png'),
                ]
              },
              {
                id: 2,
                name: 'Ponte Pedonal',
                address: 'Coimbra',
                description: 'Ponte pedonal Palheiros<br/>Câmara Municipal de Coimbra<br/>Ano: 2018',
                imgs: [
                  require('@/assets/projects/coimbra/B&W 2_edited.jpg'),
                  require('@/assets/projects/coimbra/B&W 1.jpg'),
                  require('@/assets/projects/coimbra/B&W 8.jpg'),
                  require('@/assets/projects/coimbra/B&W 12.jpg'),
                  require('@/assets/projects/coimbra/B&W 13_edited.jpg'),                  
                  require('@/assets/projects/coimbra/Ponte_Metálica_de_Coimbra_3.jpg'),
                  require('@/assets/projects/coimbra/coimbra_video.mp4'),
                ]
              },
              {
                id: 3,
                name: 'Catedral de Talatona',
                address: '',
                description: 'Catedral Talatona<br/>IURD<br/>Ano: 2017',
                imgs: [
                  require('@/assets/projects/catedral/20180424_101916.png'),
                  require('@/assets/projects/catedral/20180405_093830.png'),
                  require('@/assets/projects/catedral/20180522_104357.png'),
                  require('@/assets/projects/catedral/20180608_110541.png'),
                  require('@/assets/projects/catedral/IMG_5513.png'),
                  require('@/assets/projects/catedral/IMG-20180420-WA0006.png'),
                  require('@/assets/projects/catedral/IMG-20180425-WA0013.png'),
                ]
              },
              {
                id: 4,
                name: 'Tabuleiro Metálico',
                address: 'Sines',
                description: 'PI Sines: Estrutura Metálica do Tabuleiro<br/>Infraestruturas de Portugal<br/>Ano: 2016',
                imgs: [
                  require('@/assets/projects/sines/IMG_3583_edited.png'),
                  require('@/assets/projects/sines/IMG_3055.png'),
                  require('@/assets/projects/sines/IMG_3058.png'),
                  require('@/assets/projects/sines/IMG_3583.png'),
                  require('@/assets/projects/sines/IMG_3598.png'),
                  require('@/assets/projects/sines/IMG_3934.png'),
                  require('@/assets/projects/sines/IMG_3943.png'),
                ]
              },
              {
                id: 5,
                name: 'Ponte Metálica',
                address: 'Arouca',
                description: 'Ponte metálica Arouca<br/>Câmara Municipal de Arouca<br/>Ano: 2019',
                imgs: [
                  require('@/assets/projects/arouca/4.png'),
                  require('@/assets/projects/arouca/1.png'),
                  require('@/assets/projects/arouca/2.png'),
                  require('@/assets/projects/arouca/3.png'),
                  require('@/assets/projects/arouca/5.png'),
                  require('@/assets/projects/arouca/FOTO1.png'),
                  require('@/assets/projects/arouca/IMG_5513.png'),
                  require('@/assets/projects/arouca/IMG_5514.png'),
                  require('@/assets/projects/arouca/IMG_6010.png'),
                  require('@/assets/projects/arouca/IMG_6185.png'),
                  require('@/assets/projects/arouca/IMG_6853.png'),
                  require('@/assets/projects/arouca/IMG_6855.png'),
                  require('@/assets/projects/arouca/P_20190328_080438.png'),
                  require('@/assets/projects/arouca/PHOTO-2020-06-08-22-29-17.png'),
                ]
              },
              {
                id: 6,
                name: 'Armazém Naval',
                address: 'Leixões',
                description: ' Melhoramento da área naval<br/>APDL<br/>Ano: 2015',
                imgs: [
                  require('@/assets/projects/armazem_naval/IMG_2595_edited.png'),
                  require('@/assets/projects/armazem_naval/APDL1.png'),
                  require('@/assets/projects/armazem_naval/APDL5.png'),
                  require('@/assets/projects/armazem_naval/APDL9.png'),
                  require('@/assets/projects/armazem_naval/APDL8.png'),
                  require('@/assets/projects/armazem_naval/IMG_2597.png'),
                  require('@/assets/projects/armazem_naval/IMG_2602.png'),
                  require('@/assets/projects/armazem_naval/IMG_2606.png'),
                  require('@/assets/projects/armazem_naval/IMG_2610.png'),
                  require('@/assets/projects/armazem_naval/IMG_2655.png'),
                  require('@/assets/projects/armazem_naval/IMG_2660.png'),
                  require('@/assets/projects/armazem_naval/IMG_2679.png'),
                  require('@/assets/projects/armazem_naval/IMG_2680.png'),
                  require('@/assets/projects/armazem_naval/IMG_2682.png'),
                  require('@/assets/projects/armazem_naval/IMG_2684.png'),
                  require('@/assets/projects/armazem_naval/IMG_2686.png'),
                  require('@/assets/projects/armazem_naval/IMG_2687.png'),
                  require('@/assets/projects/armazem_naval/IMG_2689.png'),
                  require('@/assets/projects/armazem_naval/IMG_2719.png'),
                  require('@/assets/projects/armazem_naval/IMG_2720.png'),
                ]
              },
              {
                id: 7,
                name: 'Cobertura Pavilhão',
                address: 'Porto',
                description: 'Pavilhão do Estádio Universitário<br/>CDUP<br/>Ano: 2015',
                imgs: [
                  require('@/assets/projects/cobertura/IMG_2561_edited.png'),
                  require('@/assets/projects/cobertura/IMG_2246.png'),
                  require('@/assets/projects/cobertura/IMG_2249.png'),
                  require('@/assets/projects/cobertura/IMG_2262.png'),
                  require('@/assets/projects/cobertura/IMG_2449.png'),
                  require('@/assets/projects/cobertura/IMG_2480.png'),
                  require('@/assets/projects/cobertura/IMG_2562.png'),
                  require('@/assets/projects/cobertura/IMG_2579.png'),
                  require('@/assets/projects/cobertura/IMG_2707.png'),
                  require('@/assets/projects/cobertura/IMG_4010.png'),
                  require('@/assets/projects/cobertura/IMG_4011.png'),
                ]
              },
              {
                id: 8,
                name: 'Portaria',
                address: 'Setúbal',
                description: 'Portaria<br/>SAPEC - Agro<br/>Ano: 2017',
                imgs: [
                  require('@/assets/projects/portaria_setubal/16.png'),
                  require('@/assets/projects/portaria_setubal/1.png'),
                  require('@/assets/projects/portaria_setubal/2.png'),
                  require('@/assets/projects/portaria_setubal/3.png'),
                  require('@/assets/projects/portaria_setubal/4.png'),
                  require('@/assets/projects/portaria_setubal/5.png'),
                  require('@/assets/projects/portaria_setubal/6.png'),
                  require('@/assets/projects/portaria_setubal/7.png'),
                  require('@/assets/projects/portaria_setubal/8.png'),
                  require('@/assets/projects/portaria_setubal/9.png'),
                  require('@/assets/projects/portaria_setubal/10.png'),
                  require('@/assets/projects/portaria_setubal/11.png'),
                  require('@/assets/projects/portaria_setubal/12.png'),
                  require('@/assets/projects/portaria_setubal/13.png'),
                  require('@/assets/projects/portaria_setubal/14.png'),
                  require('@/assets/projects/portaria_setubal/15.png'),
                  require('@/assets/projects/portaria_setubal/17.png'),
                  require('@/assets/projects/portaria_setubal/18.png'),
                  require('@/assets/projects/portaria_setubal/19.png'),
                  require('@/assets/projects/portaria_setubal/P_20171011_171248.png'),
                ]
              },
              {
                id: 9,
                name: 'Cobertura Pavilhão',
                address: 'Abrantes',
                description: 'Escola de Abrantes<br/>Parque Escolar<br/>Ano: 2015',
                imgs: [
                  require('@/assets/projects/cobertura_abrantes/IMG_5397_edited.png'),
                  require('@/assets/projects/cobertura_abrantes/IMG_0605.png'),
                  require('@/assets/projects/cobertura_abrantes/IMG_0613.png'),
                  require('@/assets/projects/cobertura_abrantes/IMG_0622.png'),
                  require('@/assets/projects/cobertura_abrantes/IMG_0623.png'),
                  require('@/assets/projects/cobertura_abrantes/IMG_0627.png'),
                  require('@/assets/projects/cobertura_abrantes/IMG_5286.png'),
                  require('@/assets/projects/cobertura_abrantes/IMG_5288.png'),
                  require('@/assets/projects/cobertura_abrantes/IMG_5289.png'),
                  require('@/assets/projects/cobertura_abrantes/IMG_5297.png'),
                  require('@/assets/projects/cobertura_abrantes/IMG_5313.png'),
                  require('@/assets/projects/cobertura_abrantes/IMG_5382.png'),
                  require('@/assets/projects/cobertura_abrantes/IMG_5385.png'),
                  require('@/assets/projects/cobertura_abrantes/IMG_5389.png'),
                  require('@/assets/projects/cobertura_abrantes/IMG_5395.png'),
                  require('@/assets/projects/cobertura_abrantes/IMG_5396.png'),
                  require('@/assets/projects/cobertura_abrantes/IMG_5399.png'),
                  require('@/assets/projects/cobertura_abrantes/IMG_5403.png'),
                  require('@/assets/projects/cobertura_abrantes/IMG_5404.png'),
                  require('@/assets/projects/cobertura_abrantes/IMG_5405.png'),
                ]
              },
              {
                id: 10,
                name: 'Estrutura Metálica',
                address: 'Edifício - Cacia',
                description: 'Estrutura metálica Cacia<br/>Navigator<br/>Ano: 2018',
                imgs: [
                  require('@/assets/projects/edificio_cacia/20180403_141243.png'),
                  require('@/assets/projects/edificio_cacia/20180403_141240.png'),
                  require('@/assets/projects/edificio_cacia/20180510_084618.png'),
                  require('@/assets/projects/edificio_cacia/20180510_084626.png'),
                  require('@/assets/projects/edificio_cacia/20180627_135447.png'),
                  require('@/assets/projects/edificio_cacia/20180627_135535.png'),
                  require('@/assets/projects/edificio_cacia/20180706_113752.png'),
                  require('@/assets/projects/edificio_cacia/20180706_113931.png'),
                ]
              },
              {
                id: 11,
                name: 'Armazém',
                address: 'Porto - Alto',
                description: 'Subestação de Porto Alto<br/>REN<br/>Ano: 2015',
                imgs: [
                  require('@/assets/projects/armazem_porto_alto/IMG_2698_edited.png'),
                  require('@/assets/projects/armazem_porto_alto/IMG_2694.png'),
                  require('@/assets/projects/armazem_porto_alto/IMG_2696.png'),
                  require('@/assets/projects/armazem_porto_alto/IMG_2700.png'),
                  require('@/assets/projects/armazem_porto_alto/P_20151030_112149.png'),
                  require('@/assets/projects/armazem_porto_alto/P_20151030_112424.png'),
                  require('@/assets/projects/armazem_porto_alto/P_20151218_091340.png'),
                  require('@/assets/projects/armazem_porto_alto/P_20160707_164938.png'),
                  require('@/assets/projects/armazem_porto_alto/P_20160707_165443.png'),
                ]
              },
              {
                id: 12,
                name: 'Boiler House',
                address: 'Lousada',
                description: 'Boiler House Extension<br/>Continental Mabor<br/>Ano: 2016',
                imgs: [
                  require('@/assets/projects/boiler_house/IMG_3672_edited.png'),
                  require('@/assets/projects/boiler_house/IMG_3007.png'),
                  require('@/assets/projects/boiler_house/IMG_3009.png'),
                  require('@/assets/projects/boiler_house/IMG_3622.png'),
                  require('@/assets/projects/boiler_house/IMG_3677.png'),
                ]
              },
              {
                id: 13,
                name: 'Pontes Metálicas',
                address: 'Cacuaco',
                description: 'Pontes Cacuaco<br/>Ano: 2017',
                imgs: [
                  require('@/assets/projects/cacuaco/20170526_115047.png'),
                  require('@/assets/projects/cacuaco/20170526_114749.png'),
                  require('@/assets/projects/cacuaco/20170526_115025.png'),
                  require('@/assets/projects/cacuaco/IMG_3015.png'),
                  require('@/assets/projects/cacuaco/IMG_3022.png'),
                ]
              },
              {
                id: 14,
                name: 'Reabilitação Ponte Metálica',
                address: 'Leiria',
                description: 'Ponte da Pedra<br/>IP Infraestruturas de Portugal<br/>Ano: 2018',
                imgs: [
                  require('@/assets/projects/reabilitacao_leiria/P_20190222_135430.png'),
                  require('@/assets/projects/reabilitacao_leiria/IMG_5915.png'),
                  require('@/assets/projects/reabilitacao_leiria/IMG_5917.png'),
                  require('@/assets/projects/reabilitacao_leiria/IMG_5923.png'),
                  require('@/assets/projects/reabilitacao_leiria/IMG_5940.png'),
                  require('@/assets/projects/reabilitacao_leiria/P_20190222_134928_041.png'),
                ]
              },
              {
                id: 15,
                name: 'Pintura Pontes Metálicas',
                address: 'Guarda',
                description: 'Covilhã Guarda<br/>IP infraestruturas de Portugal<br/>Ano: 2018',
                imgs: [
                  require('@/assets/projects/pintura_pontes_metalicas/IMG_6590_edited.png'),
                  require('@/assets/projects/pintura_pontes_metalicas/IMG_5770.png'),
                  require('@/assets/projects/pintura_pontes_metalicas/IMG_5771.png'),
                  require('@/assets/projects/pintura_pontes_metalicas/IMG_5999.png'),
                  require('@/assets/projects/pintura_pontes_metalicas/IMG_6024.png'),
                  require('@/assets/projects/pintura_pontes_metalicas/IMG_6466.png'),
                  require('@/assets/projects/pintura_pontes_metalicas/IMG_6515.png'),
                  require('@/assets/projects/pintura_pontes_metalicas/IMG_6591.png'),
                  require('@/assets/projects/pintura_pontes_metalicas/IMG_20190404_165042.png'),
                ]
              },
              {
                id: 16,
                name: 'Continental Mabor',
                address: 'Mixing Building',
                description: 'Continental Mabor Mixing building<br/>Ano: 2019',
                imgs: [
                  require('@/assets/projects/continental_mabor/20200423_090414.png'),
                  require('@/assets/projects/continental_mabor/20191104_115914.png'),
                  require('@/assets/projects/continental_mabor/20191104_140845.png'),
                  require('@/assets/projects/continental_mabor/20191104_140902.png'),
                  require('@/assets/projects/continental_mabor/20191105_151107.png'),
                  require('@/assets/projects/continental_mabor/20191105_151110.png'),
                  require('@/assets/projects/continental_mabor/20191105_151314.png'),
                  require('@/assets/projects/continental_mabor/20191114_164129.png'),
                  require('@/assets/projects/continental_mabor/20200505_154618.png'),
                  require('@/assets/projects/continental_mabor/IMG_1211.png'),
                  require('@/assets/projects/continental_mabor/IMG_2071.png'),
                  require('@/assets/projects/continental_mabor/IMG_6138.png'),
                  require('@/assets/projects/continental_mabor/IMG_7137.png'),
                ]
              },
              {
                id: 17,
                name: 'Guardas Gonçalves',
                address: 'Zarco',
                description: 'GUARDAS GONÇALVES ZARCO<br/>Ano: 2020',
                imgs: [
                  require('@/assets/projects/zarco/WhatsApp Image 2020-07-16 at 18.12.29.png'),
                  require('@/assets/projects/zarco/WhatsApp Image 2020-07-16 at 17.55.31.png'),
                  require('@/assets/projects/zarco/WhatsApp Image 2020-07-16 at 17.55.32.png'),
                  require('@/assets/projects/zarco/WhatsApp Image 2020-07-16 at 17.55.33.png'),
                  require('@/assets/projects/zarco/WhatsApp Image 2020-07-16 at 17.55.35.png'),
                  require('@/assets/projects/zarco/WhatsApp Image 2020-07-16 at 18.15.23.png'),
                  require('@/assets/projects/zarco/WhatsApp Image 2020-07-16 at 18.15.31.png'),
                  require('@/assets/projects/zarco/WhatsApp Image 2020-07-16 at 18.37.01 (1).png'),
                  require('@/assets/projects/zarco/WhatsApp Image 2020-07-16 at 18.37.01 (2).png'),
                  require('@/assets/projects/zarco/WhatsApp Image 2020-07-16 at 18.37.02.png'),
                  require('@/assets/projects/zarco/WhatsApp Image 2020-07-16 at 18.37.04 (1).png'),
                  require('@/assets/projects/zarco/WhatsApp Image 2020-07-16 at 18.37.05.png'),
                  require('@/assets/projects/zarco/WhatsApp Image 2020-07-16 at 18.12.29 (1).png'),
                ]
              },
              {
                id: 18,
                name: 'ADPL Consolas Metálicas',
                address: '',
                description: 'APDL Consolas Metálicas<br/>Ano: 2018',
                imgs: [
                  require('@/assets/projects/apdl_consolas/P_20190628_133116 - Cópia.png'),
                  require('@/assets/projects/apdl_consolas/P_20190614_140759.png'),
                  require('@/assets/projects/apdl_consolas/P_20190614_140815.png'),
                  require('@/assets/projects/apdl_consolas/P_20190614_140855.png'),
                  require('@/assets/projects/apdl_consolas/P_20190619_152543.png'),
                  require('@/assets/projects/apdl_consolas/P_20190627_135425.png'),
                  require('@/assets/projects/apdl_consolas/P_20190628_133116 - Cópia_edited.png'),
                  require('@/assets/projects/apdl_consolas/P_20190628_133116.png'),
                ]
              },
              {
                id: 19,
                name: 'Armazém ACM',
                address: '',
                description: 'Armazém ACM<br/>Ano: 2020',
                imgs: [
                  require('@/assets/projects/armazem_acm/23.png'),
                  require('@/assets/projects/armazem_acm/7.png'),
                  require('@/assets/projects/armazem_acm/9.png'),
                  require('@/assets/projects/armazem_acm/13.png'),
                  require('@/assets/projects/armazem_acm/14.png'),
                  require('@/assets/projects/armazem_acm/15.png'),
                  require('@/assets/projects/armazem_acm/18.png'),
                  require('@/assets/projects/armazem_acm/19.png'),
                  require('@/assets/projects/armazem_acm/21.png'),
                ]
              }
            ]
        }
    },
    mounted(){
      if(this.$route.params.language != undefined){
            this.$i18n.locale = this.$route.params.language
      }
      
      this.projects.forEach(element => {
          if(element.id == this.$route.params.id){
              this.projectOpen = element
          }
      });
      this.loading = false
    },
    methods:{
      goTo(place){
          this.$router.push('/' + this.$i18n.locale + '/' + place)
      },
      isVideo(n){
        var ext = n.substr(n.lastIndexOf('.') + 1);

        console.log(ext)
        if(ext == 'mp4')
          return true


        return false
      },
      openImage(n){
        this.openModal = false
        this.imageOpen = n; 
        this.openModal = true;
      }
    }
}
</script>