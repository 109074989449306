<template>
    <v-dialog
        activator="parent"
      >
        <v-card @click="$emit('close')"
            style="background-color: transparent; width: 100%;" elevation="0">
            <v-row no-gutters>
              <v-col cols="12">
                <v-row no-gutters align="center" justify="center">
                  <v-img
                    v-if="pdf == false"
                    :src="image_url"
                    :height="750"
                    :width="750"
                    contain
                  ></v-img>
                  
                  <div v-else>
                    <vue-pdf-embed class="d-none d-lg-block" height="600" width="600" :source="image_url" />
                    <vue-pdf-embed class="d-sm-none" height="600" width="300" :source="image_url" />
                  </div>
                </v-row>
              </v-col>
            </v-row>
        </v-card>
      </v-dialog>
</template>
<script>
import VuePdfEmbed from 'vue-pdf-embed'
export default{
    name: 'ModalCertifications',
    components:{
        VuePdfEmbed
    },
    props:{
        image_url: String,
        pdf: {
            typeof: Boolean,
            default: false
        }
    }
}
</script>