<template>
    <v-container fluid>
        <!-- DESKTOP -->
        <div class="d-none d-lg-block">
            <v-card width="75%" elevation="10" style="display: block; margin: auto;" class="rounded-xl">
                <v-card-text class="ma-8 pa-8">
                    <v-row align="center">
                        <v-col cols="12" md="5" class="text-justify" style="justify: center;">
                            <span class="text-h3">{{ $t('certificados.gestao.title') }}</span><br/><br/>
                            <span class="text-subtitle text-justify">{{ $t('certificados.gestao.subtitle') }}</span>
                        </v-col>
                        <v-col cols="12" md="7">
                            <vue-pdf-embed class="pointer" @click="openCertify('certification', integrated_certification)" height="400" :source="integrated_certification" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card width="75%" elevation="10" style="display: block; margin: auto;" class="rounded-xl mt-4">
                <v-card-text class="ma-8 pa-8">
                    <v-row align="center">
                        <v-col cols="12" md="4" class="text-justify" style="justify: center;">
                            <span class="text-h3">{{ $t('certificados.qualidade.title') }}</span><br/><br/>
                            <span class="text-subtitle text-justify">{{ $t('certificados.qualidade.subtitle') }}</span>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <img 
                                @click="openCertify('Quality', images.quality.img1)"  
                                class="pointer" src="@/assets/certifications/quality/CERT_QUALIDADE.jpg" 
                                height="325">
                        </v-col>
                        <v-col cols="12" md="auto">
                            <img
                                @click="openCertify('Quality', images.quality.img2)"  
                                class="pointer" src="@/assets/certifications/quality/qualidade.jpg" height="275">
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card width="75%" elevation="10" style="display: block; margin: auto;" class="rounded-xl mt-4">
                <v-card-text class="ma-8 pa-8">
                    <v-row align="center">
                        <v-col cols="12" md="4" class="text-justify" style="justify: center;">
                            <span class="text-h3">{{ $t('certificados.seguranca.title') }}</span><br/><br/>
                            <span class="text-subtitle text-justify">{{ $t('certificados.seguranca.subtitle') }}</span>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <img 
                                @click="openCertify('Quality', images.safety.img1)" 
                                class="pointer" 
                                src="@/assets/certifications/safety/CERT_SEG.jpg" height="325">
                        </v-col>
                        <v-col cols="12" md="auto">
                            <img class="pointer" @click="openCertify('Quality', images.safety.img2)"  src="@/assets/certifications/safety/seguranca.jpg" height="275">
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card width="75%" elevation="10" style="display: block; margin: auto;" class="rounded-xl mt-4">
                <v-card-text class="ma-8 pa-8">
                    <v-row align="center">
                        <v-col cols="12" md="auto" class="text-justify" style="justify: center;">
                            <span class="text-h3">{{ $t('certificados.ambiente.title') }}</span><br/><br/>
                            <span class="text-subtitle text-justify">{{ $t('certificados.ambiente.subtitle') }}</span>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <img class="pointer" 
                                @click="openCertify('environmental', images.environmental.img1)"   src="@/assets/certifications/environmental/CERT_AMBIENTE.jpg" height="325">
                        </v-col>
                        <v-col cols="12" md="auto">
                            <img class="pointer"
                                @click="openCertify('environmental', images.environmental.img2)" src="@/assets/certifications/environmental/ambiente.jpg" height="275">
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card width="75%" elevation="10" style="display: block; margin: auto;" class="rounded-xl mt-4">
                <v-card-text class="ma-8 pa-8">
                    <v-row align="center">
                        <v-col cols="12" md="5" class="text-justify" style="justify: center;">
                            <span class="text-h3">{{ $t('certificados.marcacao.title') }}</span><br/><br/>
                            <span class="text-subtitle text-justify" v-html="$t('certificados.marcacao.subtitle')"></span>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <img class="pointer" @click="openCertify('quality_1090', images.quality_1090.img1)" src="@/assets/certifications/quality_1090/CERT_1.jpg" height="325">
                        </v-col>
                        <v-col cols="12" md="auto">
                            <img class="pointer" @click="openCertify('quality_1090', images.quality_1090.img2)" src="@/assets/certifications/quality_1090/CERT_2.jpg" height="325">
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
        <!-- MOBILE -->
        <div class="d-md-none">
            <v-card elevation="10" style="display: block; margin: auto;" class="rounded-xl">
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="12" md="5" class="text-center" style="justify: center;">
                            <span class="text-h4">{{ $t('certificados.gestao.title') }}</span><br/><br/>
                            <span class="text-subtitle text-justify">{{ $t('certificados.gestao.subtitle') }}</span>
                        </v-col>
                        <v-col cols="12" md="7">
                            <vue-pdf-embed @click="openCertify('certification', integrated_certification)" height="400" :source="integrated_certification" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card elevation="10" style="display: block; margin: auto;" class="rounded-xl mt-4">
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="12" md="4" class="text-center" style="justify: center;">
                            <span class="text-h4">{{ $t('certificados.qualidade.title') }}</span><br/><br/>
                            <span class="text-subtitle text-center">{{ $t('certificados.qualidade.subtitle') }}</span>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <img 
                                @click="openCertify('Quality', images.quality.img1)"  
                                class="pointer" src="@/assets/certifications/quality/CERT_QUALIDADE.jpg" 
                                height="325">
                        </v-col>
                        <v-col cols="12" md="auto">
                            <img
                                @click="openCertify('Quality', images.quality.img2)"  
                                class="pointer" src="@/assets/certifications/quality/qualidade.jpg" height="200">
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card elevation="10" style="display: block; margin: auto;" class="rounded-xl mt-4">
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="12" md="4" class="text-center" style="justify: center;">
                            <span class="text-h4">{{ $t('certificados.seguranca.title') }}</span><br/><br/>
                            <span class="text-subtitle text-center">{{ $t('certificados.seguranca.subtitle') }}</span>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <img 
                                @click="openCertify('Quality', images.safety.img1)" 
                                class="pointer" 
                                src="@/assets/certifications/safety/CERT_SEG.jpg" height="325">
                        </v-col>
                        <v-col cols="12" md="auto">
                            <img class="pointer" @click="openCertify('Quality', images.safety.img2)"  src="@/assets/certifications/safety/seguranca.jpg" height="200">
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card elevation="10" style="display: block; margin: auto;" class="rounded-xl mt-4">
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="12" md="auto" class="text-center" style="justify: center;">
                            <span class="text-h4">{{ $t('certificados.ambiente.title') }}</span><br/><br/>
                            <span class="text-subtitle text-center">{{ $t('certificados.ambiente.subtitle') }}</span>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <img class="pointer" 
                                @click="openCertify('environmental', images.environmental.img1)"   src="@/assets/certifications/environmental/CERT_AMBIENTE.jpg" height="325">
                        </v-col>
                        <v-col cols="12" md="auto">
                            <img class="pointer"
                                @click="openCertify('environmental', images.environmental.img2)" src="@/assets/certifications/environmental/ambiente.jpg" height="200">
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card elevation="10" style="display: block; margin: auto;" class="rounded-xl mt-4">
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="12" md="5" class="text-center" style="justify: center;">
                            <span class="text-h4">{{ $t('certificados.marcacao.title') }}</span><br/><br/>
                            <span class="text-subtitle text-center" v-html="$t('certificados.marcacao.subtitle')"></span>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <img class="pointer" @click="openCertify('quality_1090', images.quality_1090.img1)" src="@/assets/certifications/quality_1090/CERT_1.jpg" height="325">
                        </v-col>
                        <v-col cols="12" md="auto">
                            <img class="pointer" @click="openCertify('quality_1090', images.quality_1090.img2)" src="@/assets/certifications/quality_1090/CERT_2.jpg" height="325">
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
        

        <ModalCertifications 
            style="overflow: hidden !important;" v-if="certify.show" @close="certify.show = false" :pdf="certify.pdf" :image_url="certify.src" />
    </v-container>
</template>
<script>
import VuePdfEmbed from 'vue-pdf-embed'
import ModalCertifications from '@/components/ui/ModalCertifications.vue'

export default{
    components:{
        VuePdfEmbed,
        ModalCertifications
    },
    mounted(){
        if(this.$route.params.language != undefined){
            this.$i18n.locale = this.$route.params.language
        }
        let cookie = this.$cookies.get('edirio_cookie');

        if(cookie == undefined)
            return
        if(cookie == null)
            return
        if(cookie.statistics == false)
            return
            
        this.$gtag.event('certs_' + this.$i18n.locale, {
            'event_category': 'Contacto com sucesso',
            'event_label': 'Contacto com sucesso',
            'value': 'certs_' + this.$i18n.locale
        })
        window.scrollTo({top: 0, behavior: 'smooth'})
    },
    data(){
        return{
            integrated_certification: 'certifications\\02-PQAS - EDIRIO.pdf',
            images:{
                quality:{
                    img1: require('@/assets/certifications/quality/CERT_QUALIDADE.jpg'),
                    img2: require('@/assets/certifications/quality/qualidade.jpg')
                },
                safety:{
                    img1: require('@/assets/certifications/safety/CERT_SEG.jpg'),
                    img2: require('@/assets/certifications/safety/seguranca.jpg')
                },
                environmental:{
                    img1: require('@/assets/certifications/environmental/CERT_AMBIENTE.jpg'),
                    img2: require('@/assets/certifications/environmental/ambiente.jpg'),
                },
                quality_1090:{
                    img1: require('@/assets/certifications/quality_1090/CERT_1.jpg'),
                    img2: require('@/assets/certifications/quality_1090/CERT_2.jpg')
                }
            },
            certify:{
                name: null,
                src: null,
                show: false,
                pdf: false
            }
        }
    },
    methods:{
        openCertify(name, img){
            this.certify.show = false
            this.certify.name = name
            this.certify.src = img

            if(name == 'certification')
                this.certify.pdf = true
            else this.certify.pdf = false

            this.certify.show = true

        }
    }
}
</script>
<style>
.pointer{
    cursor:pointer;
}
</style>