<template>
    <v-container fluid class="fill-height ma-0 pa-0 home-bg">
        <v-card style="display: block; margin: auto; background-color: rgba(255, 255, 255, 0.75) !important;">
            <v-card-title class="ma-0 pa-0">
                <v-card style="background-color: #3EC59D;" class="ma-0 pa-0" height="70">
                    <v-container class="fill-height">
                        <v-row align="center" justify="center" no-gutters>
                            <v-col cols="12">
                                <v-icon size="x-large" color="white">mdi-check-circle-outline</v-icon>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-card-title>
            <v-card-title class="text-h3 mt-8">{{$t('success.title')}}</v-card-title>
            <v-card-text class="text-h6 mt-8">{{$t('success.msg')}}</v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default{
    name: 'SuccessView',
    mounted(){
        let cookie = this.$cookies.get('edirio_cookie');

        if(cookie == undefined)
            return
        if(cookie == null)
            return
        if(cookie.statistics == false)
            return

        this.$gtag.event('contact_success_' + this.$i18n.locale, {
            'event_category': 'Contacto com sucesso',
            'event_label': 'Contacto com sucesso',
            'value': 'contact_success_' + this.$i18n.locale
        })
    }
}
</script>
<style>
.home-bg {
  /* The image used */
  background-image: url("@/assets/homepage/bridge_view.jpg");

  /* Full height */
  height: 100%; 

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

</style>