<template>
    <v-dialog activator="parent" v-if="!loading">
        <!-- DESKTOP -->
        <div class="d-none d-lg-block">
            <v-card width="75%" style="margin: auto; display: block;">
                <v-carousel show-arrows="hover" v-model="selectedModel">                
                    <v-carousel-item
                        v-for="(item,i) in items"
                        :key="i"
                        :src="item"
                        cover
                    >
                    <v-sheet height="100%" tile>
                        <v-row class="fill-height" align="center" justify="center">
                            <v-img v-if="!isVideo(item)" cover :src="item" >                            
                                <v-btn @click="$emit('close')" style="float: right; background-color: rgba(255, 255, 255, 0.75);" variant="text" class="font-weight-bold mt-4 mr-4">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-img>

                            <video controls v-else width="750">
                                <source :src="item" type="video/mp4">
                                Your browser does not support HTML video.
                            </video>
                        </v-row>
                    </v-sheet>     
                
                    </v-carousel-item>
                </v-carousel>
            </v-card>
        </div>
        <!-- MOBILE -->
        <div class="d-md-none">
            <v-card style="margin: auto; display: block;">
                <v-carousel show-arrows="hover" v-model="selectedModel">                
                    <v-carousel-item
                        v-for="(item,i) in items"
                        :key="i"
                        :src="item"
                        cover
                    >
                    <v-sheet height="100%" tile>
                        <v-row class="fill-height" align="center" justify="center">
                            <v-img v-if="!isVideo(item)" cover :src="item" >                            
                                <v-btn @click="$emit('close')" style="float: right; background-color: rgba(255, 255, 255, 0.75);" variant="text" class="font-weight-bold mt-4 mr-4">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-img>

                            <video controls v-else width="250">
                                <source :src="item" type="video/mp4">
                                Your browser does not support HTML video.
                            </video>
                        </v-row>
                    </v-sheet>     
                
                    </v-carousel-item>
                </v-carousel>
            </v-card>
        </div>
    </v-dialog>
</template>
<script>
export default{
    name: "ModalSlideshow",
    props:{
        items: Array,
        selected: String
    },
    watch:{
        selected:{
            immediate: true,
            handler(){
                this.updateSelectedImage()
            }
        }
    },
    data(){
        return{
            selectedModel: 0,
            loading: true
        }
    },
    mounted(){
        this.updateSelectedImage()
    },
    methods:{
        isVideo(n){
            var ext = n.substr(n.lastIndexOf('.') + 1);

            console.log(ext)
            if(ext == 'mp4')
            return true


            return false
        },
        updateSelectedImage(){
            this.loading = true
            let i = 0
            this.items.forEach(element => {
                if(element == this.selected)
                    this.selectedModel = i

                i++
            });            
            this.loading = false
        }
    }
}
</script>