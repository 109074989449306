import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/validations'

import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import {createI18n} from 'vue-i18n'
import messages from './locales'
import VueGtag from 'vue-gtag'
import { createHead } from '@vueuse/head'
import VueCookies from 'vue-cookies'

const head = createHead()

import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader


const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#1B3544',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    // Logo color
    accent: '#EA322A'
  },
}
  
const vuetify = createVuetify({
    components,
    directives,
    theme: {
      defaultTheme: 'myCustomLightTheme',
      themes: {
        myCustomLightTheme,
      },
    },
  });

export const i18n = new createI18n({
  locale: 'pt',
  fallbackLocale: 'pt',
  messages
})

createApp(App)
.use(store)
.use(router)
.use(vuetify)
.use(i18n)
.use(head)
.use(VueCookies, { expires: '7d' })
.use(VueGtag, {
  config: {
      id: 'G-ZBRKTY24HG',
      params: {
          send_page_view: true
      }
  }
})
.mount('#app')
